import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'
import SEO from '../components/seo'
import PhotoGalleryComponent from '../components/photo-gallery-component'


const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
  align-items: stretch;
`

const Heading = styled.div`
  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const GalleryPage = (props) => {
  const data = useStaticQuery(graphql`
    query GalleryPhotos {
      contentfulPhotoGallery(contentful_id: {eq: "2QeL3Tr2nZkDw2ciFfNVBc"}) {
       
        title
        contentful_id
        id
        
        description {
          description
        }
        photos {
          id
          title
          gatsbyImageData(
            width: 150
            quality: 70
            aspectRatio: 1.26153846154
            placeholder: NONE
          )
          fullSize: gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
        }
      }
    }
  `)
  console.log("data", data)
  return (
    <Layout pageProps={props}>
      <SEO
        title={'Photo Gallery - LVs Iron Works'}
        description={
          'Browse our photo gallery of recently completed projects to find inspiration and contemporary remodeling ideas.'
        }
      />
      <div className='text-center  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-12'>
        <h1 className='mx-auto font-bold text-3xl md:text-4xl'>Recent Projects</h1>
        <p className='text-gray-600 md:text-lg px-4'>
          Browse our photo gallery to see some of our recently completed
          projects.
        </p>

        {data &&
          data.contentfulPhotoGallery
          &&
          data.contentfulPhotoGallery.photos ? (
          <PhotoGalleryComponent gallery={data.contentfulPhotoGallery} hideTitle={true} />
        ) : null}

      </div>
    </Layout>
  )
}

export default GalleryPage
